import qs from "qs"
import { fetchJson, localized, ninjaReportError, showErrorMessage, isFeatureEnabled } from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { getNodeLocation } from "js/state/actions/locations/deviceLocations"

export const getClient = clientId => fetchJson(`/client/${clientId}`)

export const getOrganization = async organizationId => {
  try {
    const response = await getClient(organizationId)
    if (response.resultCode === "SUCCESS") {
      return response.client
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    ninjaReportError(error)
  }
}

export const getOrganizationsByIds = async ids => {
  const query = qs.stringify({ id: [ids] }, { indices: false })
  try {
    return await fetchJson(`/client?${query}`)
  } catch (error) {
    showErrorMessage(localized("Error fetching organizations"))
    ninjaReportError(error)
  }
}

export const getOrganizationAndLocation = async node => {
  await window.customerList.fetchByIds([node.clientId])
  const [organization, location] = await Promise.all([getOrganization(node.clientId), getNodeLocation(node.locationId)])
  return { organization, location }
}

export const getOrganizationDomainControllers = async (clientId, filters = {}) => {
  const { items } = await fetchJson(`/search/runner?`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        searchCriteria: [
          {
            type: "customer",
            id: -1,
            customFields: `{"selectedValues":[${clientId}]}`,
          },
          {
            type: "computer-system-roles",
            id: -2,
            customFields: `{"selectedValues":["DOMAIN_CTRL","DOMAIN_BAKCTRL"]}`,
          },
          ...(filters.isUp
            ? [
                {
                  type: "node-status",
                  id: -3,
                  customFields: `{"selectedValues":["isUp"],"include":"all"}`,
                },
              ]
            : []),
        ],
      }),
    },
  })

  return items
}

export const getOrganizationDevicesByType = async (clientId, type) => {
  const devicesByType = {
    SERVERS: ["isWindowsServer", ...(isFeatureEnabled("macff") ? ["isMacServer"] : [])],
    WORKSTATIONS: [
      "isWindowsDesktop",
      "isWindowsLaptop",
      ...(isFeatureEnabled("macff") ? ["isMacDesktop", "isMacLaptop"] : []),
    ],
  }

  const { items } = await fetchJson(`/search/runner?`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        searchCriteria: [
          { type: "customer", id: -1, customFields: `{"selectedValues":[${clientId}]}` },
          {
            type: "node-type",
            id: -2,
            customFields: JSON.stringify({ selectedValues: devicesByType[type] }),
          },
        ],
      }),
    },
  })

  return items
}

export const getOrganizationDevicesByBackupProductCode = async (clientId, productCode) => {
  const windowsNodeTypes = ["isWindowsWorkstation", "isWindowsServer"]
  const macOsNodeTypes =
    isFeatureEnabled("macff") && productCode === "LOCKHART" ? ["isMacDesktop", "isMacLaptop", "isMacServer"] : []

  const { items } = await fetchJson(`/search/runner?`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        searchCriteria: [
          { type: "customer", id: -1, customFields: `{"selectedValues":[${clientId}]}` },
          {
            type: "node-type",
            id: -2,
            customFields: JSON.stringify({ selectedValues: [...windowsNodeTypes, ...macOsNodeTypes] }),
          },
        ],
      }),
    },
  })

  return items
}

export const importOrganizations = ({ organizations, copyTemplate }) => {
  return fetchJson("/client/bulk-import", {
    options: {
      method: "POST",
      body: JSON.stringify({
        names: organizations.map(({ name }) => name),
        copyTemplate,
      }),
    },
  })
}

export const copyOrganizationSettings = ({ name, sourceClientId, settings }) => {
  return fetchJson("/client/copy", {
    options: {
      method: "POST",
      body: JSON.stringify({
        name,
        copyTemplate: {
          sourceClientId,
          ...settings,
        },
      }),
    },
  })
}

export const queryOrganizations = (pageSize = 50) => {
  return fetchJson(`/query/organizations?pageSize=${pageSize}`)
}

export const setClientSystray = ({ systrayId, clientIds, enable }) =>
  fetchJson("/client/systray/bulk", {
    options: {
      method: "POST",
      body: JSON.stringify({
        systrayId: Number(systrayId),
        clientIds,
        enable,
      }),
    },
  })

export const bulkEditSplashtop = setting => {
  return bulkEdit("splashtop", setting)
}

export const bulkEditTeamViewer = setting => {
  return bulkEdit("teamviewer", setting)
}

export const bulkEditNinjaRemote = setting => {
  return bulkEdit("ninja-remote", setting)
}

const bulkEdit = (appName, setting) => {
  return fetchJson(`/client/${appName}/bulk`, {
    options: {
      method: "POST",
      body: JSON.stringify(setting),
    },
  })
}

export const bulkEditNodeApprovals = ({ orgs, mode }) => {
  return fetchJson("/client/node-approval/bulk", {
    options: {
      method: "POST",
      body: JSON.stringify({
        clients: orgs.map(org => org.id),
        approvalMode: mode,
      }),
    },
  })
}
