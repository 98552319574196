import styled from "@emotion/styled"
import { Body } from "@ninjaone/components"
import tokens, { spacing, typography } from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"

export const StyledRow = styled(Flex)`
  padding: ${({ padding }) => padding ?? `${tokens.spacing[4]} ${tokens.spacing[3]}`};
  width: 100%;
  &:hover {
    background: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  }
`

export const FlexRowWrapper = styled(Flex)`
  align-items: center;
  gap: ${tokens.spacing[8]};
  justify-content: space-between;
`

export const LabelWrapper = styled(Box)`
  flex-shrink: 0;
`

export const SelectWrapper = styled(Box)`
  width: 300px;
`

export const StyledTitleLabel = ({ text, marginTop }) => {
  const { fontWeight } = typography
  return (
    <Body marginBottom={spacing[2]} color="colorTextStrong" {...{ marginTop }} fontWeight={fontWeight.medium}>
      {text}
    </Body>
  )
}
